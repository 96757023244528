@charset "UTF-8";

/*
	サイトの基本となるCSS
*/

/* 余白のリセット */
body, div, dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
object, iframe, pre, code, p, blockquote,
form, fieldset, legend,
table, th, td, caption, tbody, tfoot, thead {
	margin: 0;
	padding: 0;
}

/* body */
body {
	text-align:center;
	line-height: 1.75;
	word-break: break-all; 
	-webkit-text-size-adjust:100%;
	font-family:  $font-jp;
	_font-family: $font-msp;;
	font-size: 18px;
	*font-size: 113%;	
	overflow-x: hidden;
	color: #000;
}
body.macOs , body.win8-1 {
	font-family: $font-yugo;
}

/*
Font-size list (base: 14px)
 57% =  8px
 64% =  9px
 71% = 10px
 79% = 11px        150% = 21px        221% = 31px
 86% = 12px        157% = 22px        229% = 32px
 93% = 13px        163% = 23px        236% = 33px
100% = 14px        171% = 24px        243% = 34px
107% = 15px        179% = 25px        250% = 35px
114% = 16px        186% = 26px        257% = 36px
121% = 17px        193% = 27px        264% = 37px
129% = 18px        200% = 28px        271% = 38px
136% = 19px        208% = 29px        276% = 39px
143% = 20px        214% = 30px        286% = 40px
*/

/* リンク色 */
a { color: $color--link-basic ; }	
a:hover, a:active  {
	text-decoration: none;
	color:$color--link-hover;
}

/* hover時の画像 */
a:hover {
	img {
		-moz-opacity:0.7;
		opacity:0.7;
		filter: alpha(opacity=70);
		border:none;
	}
}
/* IE8 */
html>/**/body a:hover img {
    display /*\**/:inline-block\9;
    zoom /*\**/: 1\9;
}
/* 新旧Firefox */
a:hover img, x:-moz-any-link { background: #fff;}
a:hover img, x:-moz-any-link, x:default { background: #fff;}
/* for IE7: page zoom bug fix */
*:first-child+html body,
*:first-child+html br { letter-spacing: 0; }


/* 画像 */
img{
	vertical-align:bottom;
	max-width: 100%;
}

/* Clearfix */
.clearfix:after{
  content: "."; 
  display: block; 
  height: 0; 
  font-size:0;	
  clear: both; 
  visibility:hidden;
}
	
.clearfix {display: inline-block;} 

/* Hides from IE Mac */
* html .clearfix {height: 1%;}
.clearfix {display:block;}
/* End Hack */ 

