@charset "UTF-8";
/*
	サイト全体のリストの設定
*/

/* アイコン型リストの設定 */
.list-mark{
	list-style:none;
	li{
		background: url(../img/common/mark/ico--mark-01.png) 4px 7px no-repeat;
		padding-left: 23px;
	}
}
.list-circle{
	list-style:none;
	li{
		background: url(../img/common/mark/ico--circle-01.png) 2px 4px no-repeat;
		padding-left: 23px;
	}
}
/* ボーダー型リストの設定 */
.list-border{
	list-style:none;
	li{
		padding-left: 5px;
		border-bottom: 1px dashed #ABA073;
	}
}
/* 番号型リストの設定 */
.list-numbering{
	list-style:decimal;
	padding-left: 25px;
	li{
		display: list-item;
		margin-bottom: 10px;
		.list-numbering{
			list-style:lower-roman inside;
			li{
				text-indent: -1.2em;
			}
		}
	}
}
/* 通常リストの設定 */
.list-nomal{
	padding-left: 20px;
	li{
		margin-bottom: 5px;
	}
}
/* リストマークなし */
.list-style-none{
	list-style: none;
}
