@charset "UTF-8";
/*
	サイト全体のテーブルの設定
*/
/* テーブルの設定 */
.table-ptn--01{
	th{
		background:#A6E1F3;
		border:1px solid #fff;
		padding:15px 10px;
	}
	td{
		background: #fff;
		border:1px solid #B3B3B3;
		padding:15px 10px;
	}
}

.table-ptn--02{
	th{
		background:#FDF18A;
		border:1px solid #fff;
		padding:15px 10px;
	}
	td{
		background: #fff;
		border:1px solid #B3B3B3;
		padding:15px 10px;
	}
}
.table-ptn--03{
	th{
		border-bottom:1px dashed #ABA073;
		padding:10px;
		font-weight: bold;
	}
	td{
		border-bottom:1px dashed #ABA073;
		padding:10px;
	}
}
