@charset "UTF-8";
/*
	サイト全体の見出しの設定
*/

/* ページタイトル */
.page-title{
	padding-bottom: 10px;
	margin-bottom:40px;
	position: relative;
	line-height: 1.2;
	font-size: 188%;
	&:after{
		background: #FFE800;
		content: ' ';
		width: 200px;
		height: 7px;
		position: absolute;
		left: 0;
		bottom: -7px;
	}
}
/* 見出し */
.title-lv1{
	background: url(../img/common/heading/bgi--title-lv1--full.png) left top no-repeat;
	padding: 25px 10px 25px 30px;
	margin-bottom:15px;
	line-height: 1.2;
	font-size: 125%;
}
.layout-main-column .title-lv1{
	background: url(../img/common/heading/bgi--title-lv1.png) left top no-repeat;
}

.title-lv2{
	background: url(../img/common/item/line--dashed.png) left bottom no-repeat;
	padding-bottom: 5px;
	margin-bottom:15px;
	font-size: 113%;
}
.title-lv3{
	padding:0 0 0 17px;
	margin-bottom:5px;
	color: #83300E;
}
/* 汎用見出し */
.title-other-01{
	background: linear-gradient(transparent 60%, #FFF238 80%);
	display: inline-block;
	padding: 0 20px 0 5px;
	position: relative;
	font-size: 113%;
	color: $color--site-main;
}
.title-other-02{
	font-size: 125%;
	color: $color--site-sub;
}

