@charset "UTF-8";
/*
	サイト全体のWordPress記事の設定
*/

/* ブログ・お知らせの設定 */
.blog-list{
	list-style: none;
	li{
		width: 250px;
		float: left;
		margin-right: 27px;
		margin-bottom: 30px;
		border: 1px solid  #ccc;
		box-sizing: border-box;
		position: relative;
		z-index: 10;
		&:nth-child(3n){
			margin-right: 0;
		}
		&:nth-child(3n+1){
			clear: both;
		}
		&:before , &:after{
			z-index: -1;
			position: absolute;
			content: "";
			bottom: 15px;
			left: 10px;
			width: 50%;
			top: 80%;
			max-width:300px;
			background: #777;
			box-shadow: 0 20px 10px rgba(0,0,0,0.3);;
			transform: rotate(-3deg);				
		}
		&:after{
			transform: rotate(3deg);
			right: 10px;
			left: auto;
		}
		span{
			display: block;
		}
		a{
			text-decoration: none;
		}
	}
	.blog-list__container{
		background: #fff;
		padding: 15px;
	}
	.blog-list__date{
		margin-bottom: 5px;
		font-size: 94%;
		font-family: Meiryo;
	}
	.blog-list__article-title{
		height: 64px;
		margin-bottom: 20px;
		line-height: 1.3;
		font-size: 122%;
		font-weight: bold;
		text-decoration: underline;
	}
	.blog-list__more{
		border: 1px solid  #B2B2B2;
		padding: 10px;
		border-radius: 3px;
		text-align: center;
		font-size: 78%;
		color: #808080;
	}
	a:hover{
		.blog-list__more{
			background: $color--site-main;
			border: 1px solid  $color--site-main;
			color: #fff;
		}
	}
}
.sp-blog-list{
	li{
		@extend .clearfix;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid  #ccc;
		box-sizing: border-box;
		span{
			display: block;
		}
		a{
			text-decoration: none;
		}
		img{
			width: 80px;
			float: left;
			margin-right: 10px;
		}
	}
	.blog-list__date{
		font-size: 94%;
		font-family: Meiryo;
	}
	.blog-list__article-title{
		line-height: 1.3;
		font-size: 122%;
		font-weight: bold;
		text-decoration: underline;
	}
	.blog-list__more{
		border: 1px solid  #B2B2B2;
		padding: 10px;
		border-radius: 3px;
		text-align: center;
		font-size: 78%;
		color: #808080;
	}
	a:hover{
		.blog-list__more{
			background: $color--site-main;
			border: 1px solid  $color--site-main;
			color: #fff;
		}
	}
}

.article{
	border-bottom: 1px dashed #ABA073;
	padding:  0 0 20px 0;
	margin-bottom: 20px;
	.article__date{
		display: block;
		width: 20%;
		border-right: 1px dashed #666666;
		float: left;
	}
	.article__title{
		width: 78%;
		float: left;
		font-weight: normal;
		font-size: 100%;
	}
}

/* ページネーションの設定 */
.pagination {
	position: relative;
	padding: 20px 0;
	clear: both;
	text-align: center;
	line-height: 40px;
	font-size: 12px;
	span,  a {
		background-color: #fff;
		display: inline-block;
		width: 40px;
		margin: 2px 5px 2px 0;
		text-align: center;
		text-decoration: none;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		font-size: 125%;
		color: $color--site-main;;
	}
	a {
		border: 1px solid $color--site-main;;
		&:hover{
			background-color: $color--site-main;;
			border: 1px solid $color--site-main;;
			color: #fff;
		}
	}
	.current{
		background-color: $color--site-main;;
		border: 1px solid $color--site-main;;
		color: #fff;
	}
}

