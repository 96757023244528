@charset "UTF-8";
/*
	サイト全体のリンク・ボタンの設定
*/
/* ボタンの設定 */
.btn{
	display: inline-block;
	padding: 8px 18px;
	img{
		vertical-align: text-top;
	}
}
.btn--full{
	display: block;
	padding: 12px 0;
	border-radius: 3px;
	text-align: center;
	behavior: url(PIE.htc);
}
.btn--main{
	background: #5EC3DA;
	box-shadow:  3px 3px #9CDCED;
	text-decoration: none;
	color: #fff;
}
.btn--yellow{
	background: #FBED21;
	box-shadow:  3px 3px #FBD08A;
	text-decoration: none;
	color: #000;
}
.btn--black{
	background: #000;
	box-shadow:  3px 3px #999;
	text-decoration: none;
	color: #fff;
}
.btn--pink{
	background: #EC1E79;
	box-shadow:  3px 3px #C20067;
	text-decoration: none;
	color: #fff;
}

.btn--white{
	background: #fff;
	border: 2px solid $color--site-main;
	text-decoration: none;
	color: $color--site-main;
}
