@charset "UTF-8";
/*
	サイト全体のレイアウトグリッドの指定
*/

/* ヘッダー */
.layout-header {
	width: 100%;
}

/* フッター */
.layout-footer {
	width: 100%;
}
.layout-footer-half{
	width: 460px;
	float: left;
}

/* ページ幅 */
.layout-container{
	width: $page-width;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}
/* メインカラム */
.layout-main-column{
	width:805px;
	float:left;
}
/* サブカラム */
.layout-sub-column{
	width:250px;
	float:right;
}

/* 汎用レイアウト */
.layout-section{
	margin-bottom: 60px;
}
.layout-half{
	width:49%;
}
.layout-1-third{
	width:32%;
}
.layout-2-third{
	width:66%;
}
.layout-3-col{
	float:left;
	width:32%;
	margin-right:2%;
	&.last{
		margin-right:0%;
	}
}
.layout-4-col{
	float:left;
	width:24%;
	margin-right:1%;
	&.last{
		margin-right: 0;
	}
}