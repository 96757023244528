@charset "UTF-8";
/*
	サイト全体のリンク・ボタンの設定
*/
/* ブロックの設定 */
.box-sub--01{
	background: #fff;
	padding:15px;
	border:2px solid #6FCFEB;
}
.box-sub--02{
	background: #F1F1F1;
	padding: 15px;
}
