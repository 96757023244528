@charset "UTF-8";
/*
	サイト全体のナビゲーション設定
*/
/* グローバルナビの設定 */
.global-nav{
	display: block;
	height:$grobal-item-height;
	margin-bottom: 30px;
	clear:both;
	ul{
		background: url(../img/common/menu/bgi--item.jpg) 100% 51px no-repeat;
		width: $page-width;
		list-style:none;
		margin: 0 auto;
	}
	li{
		background: url(../img/common/menu/bgi--item.jpg) 0 51px no-repeat;
		float:left;
		padding-left: 8px;
	}
	a , span{
		background-image:url(../img/common/menu/global-nav.jpg);
		display:block;
		height:$grobal-item-height;
		overflow:hidden;
		text-indent: -9999px;
	}
	
	.global-nav-item--01{
		width: 130px;
		a{
			background-position:  -7px  $grobal-item-height;
		}
		&.active span{
			background-position: -7px  $grobal-item-height;
		}
		span{
			background-position: -7px 0;
		}
	}
	.global-nav-item--02{
		width: 138px;
		a{
			background-position:  -147px  $grobal-item-height;
		}
		&.active span{
			background-position: -147px  $grobal-item-height;
		}
		span{
			background-position: -147px 0;
		}
	}
	.global-nav-item--03{
		background: url(../img/common/menu/bgi--item.jpg) 0 51px no-repeat , url(../img/common/menu/bgi--item.jpg) 100% 51px no-repeat;
		width: 137px;
		margin-right: 224px;
		padding-right: 8px;
		a{
			background-position:  -292px  $grobal-item-height;
		}
		&.active span{
			background-position: -292px  $grobal-item-height;
		}
		span{
			background-position: -292px 0;
		}
	}
	.global-nav-item--04{
		width: 128px;
		a{
			background-position:  -668px  $grobal-item-height;
		}
		&.active span{
			background-position: -668px  $grobal-item-height;
		}
		span{
			background-position: -668px 0;
		}
	}
	.global-nav-item--05{
		width: 139px;
		a{
			background-position:  -805px  $grobal-item-height;
		}
		&.active span{
			background-position: -805px  $grobal-item-height;
		}
		span{
			background-position: -805px 0;
		}
	}
	.global-nav-item--06{
		width: 140px;
		a{
			background-position:  -951px  $grobal-item-height;
		}
		&.active span{
			background-position: -951px  $grobal-item-height;
		}
		span{
			background-position: -951px 0;
		}
	}

}

/* 固定ナビの設定 */
.floating-nav{
	background: url(../img/common/menu/floating-nav/bgi--floating-nav.png);
	width: 100%;
	padding-top: 8px;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
	position: fixed;
	left: 0;
	top: -130px;
	.floating-nav__logo{
		float: left;
	}
	.floating-nav__list{
		width: 875px;
		float: right;
		list-style: none;
		li{
			background: url(../img/common/menu/floating-nav/bgi--line.jpg) right center no-repeat;
			width: 145px;
			padding-right: 1px;
			float: left;
			&:last-child{
				padding-right: 0;
			}
			a{
				display: block;
				border-bottom: 3px solid  #EC1E79;
				span{
					display: block;
					text-align: center;
					line-height: 62px;
					img{
						vertical-align: middle;
						opacity: 0.7;
					}
				}
				&:hover{
					img{
						opacity: 1;
					}
				}
			}
		}
	}
}

/* ローカルナビの設定 */
.side-nav{
	margin-bottom:20px;
	.side-nav__title{
		background: url(../img/common/subcolumn/bgi--side-nav-dt.png) right top no-repeat;
		padding: 15px 10px;
		font-weight: bold;
		font-size: 150%;
	}
	.side-nav__list{
		list-style:none;
		li{
			margin-bottom: 10px;
			a{
				display: block;
				padding: 5px 5px 5px 5px;
				border: 1px dashed $color--site-main;
				line-height: 1.3;
			}
		}
	}
}

/* フッターナビ */
.footer-nav{
	width: 1100px;
	margin: 0 auto;
	padding: 60px 120px 30px;
	position: relative;
	box-sizing: border-box;
	a{
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
	.footer-response{
		width: 228px;
		position:absolute;
		right: 40px;
		top: -200px;
		a{
			position: absolute;
			left: 20px;
			bottom: 40px;
		}
	}
	.footer-nav__item{
		width: 166px;
		float: left;
		dt{
			font-weight: bold;
			font-size: 83%;
		}
		ul{
			list-style: none;
			font-size: 78%;
			li{
				margin-top: 15px;
				line-height: 1;
			}
		}
	}
}


