@charset "UTF-8";

/* setting */
@import "setting/_color.scss";
@import "setting/_font.scss";
@import "setting/_size.scss";

/* tool */
@import "tool/_mixin.scss";

/* base */
@import "base/_normalize.scss";
@import "base/_generic.scss";

/* layout */
@import "layout/_grid.scss";
@import "layout/_layout-header.scss";
@import "layout/_layout-footer.scss";

/* module */
@import "module/_navigation.scss";
@import "module/_heading.scss";
@import "module/_table.scss";
@import "module/_btn-link.scss";
@import "module/_list.scss";
@import "module/_block.scss";
@import "module/_articlelist.scss";
@import "module/_item.scss";
@import "module/_utility.scss";


/* theme */
@import "theme/_decoration.scss";
