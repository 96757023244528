@charset "UTF-8";
/*
	サイト全体で使うフォントの指定
*/

/* メイン・サブカラー */
$color--site-main : #6FCFEB; 
$color--site-sub : #FF7BAB;

/* リンク色 */
$color--link-basic : #333;
$color--link-hover : #666 ;
