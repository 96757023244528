@charset "UTF-8";
/*
	フッターの指定
*/

/* フッター外枠 */
.footer {
	margin-top: 60px;
	clear:both;
	display: block;
	position: relative;
	text-align: left;
}
.footer__bg{
	background: #F6F9DB;
	border-bottom: 2px solid  #EC1C24;
	.layout-container{
		padding: 40px 40px 50px;
		background: url(../img/common/footer/bgi--footer.png) 890px top no-repeat;
		position: relative;
	}
	.footer__ribbon{
		position: absolute;
		left: 0;
		bottom: -12px;
	}
}
/* フッター　会社情報 */
.footer-site-name{
	margin-bottom: 10px;
}
.footer-address{
	width: 305px;
	margin-right: 37px;
	float: left;
}
address{
	font-style: normal;
	font-size: 83%;
}
.footer-address__mail{
	margin: 5px 0;
	dt{
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
	}
	dd{
		display: inline-block;
	}
}
.footer-calendar{
	width: 228px;
	margin-right: 37px;
	float: left;
}
.footer-service-area{
	width: 217px;
	float: left;
	.footer-service-area__title{
		border-left: 3px solid  #F6921E;
		padding-left: 10px;
		margin-bottom: 10px;
		line-height: 1;
		font-weight: bold;
	}
}
/* コピーライト */
.copy {
	width: 1100px;
	margin: 0 auto;
	padding: 0 120px 30px;
	display: block;
	box-sizing: border-box;
	line-height: 30px;
	font-size: 67%;
	color: #989898;
}
