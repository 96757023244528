@charset "UTF-8";
/*
	サイト全体のユーティリティ設定
*/

/* リード文の設定 */
.u-lede{
	@extend .wf-notosansjapanese;
	line-height: 1.2;
	font-size: 163%;
	font-weight: bold;
}
.sp .u-lede{
	@extend .wf-notosansjapanese;
	line-height: 1.2;
	font-size: 125%;
	font-weight: bold;
}


/* 文字サイズの設定 */
.u-font-size--small{
	font-size: 78%;
}
.u-font-size--large{
	font-size: 122%;
}
.u-font-size--x-large{
	font-size: 133%;
}
.u-font-size--xx-large{
	font-size: 144%;
}

/* 文字色、装飾の設定 */
.u-font-bold{
	font-weight:bold;
}
.u-color-red{
	color: red;
}
.u-color-main{
	color: $color--site-main;
}
.u-color-sub{
	color: #FF0087;
}
/* マーカーの設定 */
.u-marker-yellow{
	background: linear-gradient(transparent 60%, #FFF238 60%);
	padding: 0 2px;
	position: relative;
	behavior: url(PIE.htc);
}
.u-marker-pink{
	background: linear-gradient(transparent 40%, #FFD4D3 40%);
	padding: 0 2px;
	position: relative;	
	behavior: url(PIE.htc);
}

/* 段落の設定 */
.u-txt {
	padding:0 1px;
} 
.u-paragraph {
	padding:0 1px 0 1px;
	margin-bottom:1em;
} 
.u-txt-sub {
	font-size: 85% !important;
	color: #666;
}
.u-txt-attention{
	padding:0 0 0 0.7em;
	text-indent:-0.7em;
	color: #C60;
}

/* 文字寄せの設定 */
.u-textalign-center{
	text-align:center;
}
.u-textalign-right{
	text-align:right;
}
.u-textalign-left{
	text-align:left;
}

/* floatの設定 */
.u-float-left{
	float:left;
}
.u-float-right{
	float:right;
}
.u-clear{
	clear:both;
}
.u-overflow-hidden{
	overflow: hidden;
}

/* 画像の寄せ */
img.alignleft{
	float:left;
	margin:0 15px 15px 0;
}
img.alignright{
	float:right;
	margin:0 0 15px 15px;
}
img.aligncenter{
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* 線の設定 */
.u-line-dashed{
	border: none;
	border-top: 1px dashed #ccc;
	height: 1px;
}

/* マージンボトムの設定 */
.u-margin-bottom--small{
	margin-bottom:10px;
}
.u-margin-bottom{
	margin-bottom:20px;
}
.u-margin-bottom--large{
	margin-bottom:40px;
}
.u-margin-bottom--x-large{
	margin-bottom:60px;
}