@charset "UTF-8";
/*
	サイト全体で使うフォントの指定
*/
/* ページのwidth */
$page-width : 1100px;

/* グローバルのアイテム数 */
$grobal-item-count : 6;

/* グローバルのアイテムwidth */
$grobal-item-width : floor($page-width / $grobal-item-count );

/* グローバルのアイテムheight */
$grobal-item-height: 74px;
