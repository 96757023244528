@charset "UTF-8";
/*
	サイト全体のアイテムの設定
*/

/* カテゴリキャッチ */
.catch{
	background: url(../img/common/catch/pic--catch.png) center top no-repeat , url(../img/common/catch/bgi--catch.jpg) center top ;
	height: 270px;
	margin-bottom: 40px;
}
.topic-path{
	font-size: 88%;
	padding: 10px 0;
	margin-bottom: 60px;
	color: #4d4d4d;
}

.side-contact{
	position: relative;
	a{
		position: absolute;
		top: 373px;
		left: 30px;
	}
}
.response-area{
	background: url(../img/common/item/response/bgi--area.png) left top no-repeat;
	width: 811px;
	height: 373px;
	box-sizing: border-box;
	padding: 50px 50px 0;
	margin: 0 auto 40px;
	text-align: center;
	.response-area__title{
		margin-bottom: 30px;
	}
	.response-area__tel{
		margin-top: 12px;
		display: inline-block;
		float: right;
	}
	.response-area__contact{
		text-align: left;
	}
	.response-area__text{
		line-height: 1.5;
		margin: 5px 0;
		font-size: 67%;
	}
}

/* よくある質問 */
.faq-category{
	@extend .clearfix;
	list-style: none;
	margin-bottom: 40px;
	li{
		width: 145px;
		float: left;
		margin-right: 20px;
		font-size: 94%;
		&:last-child{
			margin-right: 0;
		}
		a{
			background: #F1F1F1 url(../img/faq/pic--arrow.png) center bottom 5px no-repeat;
			display: block;
			padding: 10px 0 20px;
			text-align: center;
			img{
				display: block;
				margin: 0 auto;
			}
		}
	}
}
.faq{
	dt{
		background: url(../img/faq/ico--faq-q.jpg) left top no-repeat;
		padding: 0 0 10px 45px;
		font-size: 118%;
		font-weight: bold;
	}
	dd{
		background: #F1F1F1;
		padding: 15px;
		margin-bottom: 30px;
	}
}

/* お客様の声 */
.voice{
	margin-bottom: 60px;
	.voice__title{
		@extend .title-lv1;
	}
	.voice__photo{
		width: 500px;
		text-align: right;
		float: left;
	}
	.voice__img{
		border: 1px solid  #ccc;
		margin-bottom: 10px;
	}
	.voice__contents{
		width: 560px;
		float: right;
		line-height: 1.6;
		dt{
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 111%;
		}
	}
}

/* スタッフ */
.staff{
	@extend .clearfix;
	margin-bottom: 60px;
	.staff__info{
		width: 485px;
		float: right;
	}
	.staff__photo{
		width: 295px;
		float: left;
	}
	.staff__comment{
		font-size: 88%;
		color: #50BEF0;
	}
	.staff__name{
		background: url(../img/company/staff/bgi--name.jpg) left bottom no-repeat;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}
	.staff__profile{
		dt{
			font-weight: bold;
			font-size: 111%;
		}
		dd{
			margin-bottom: 20px;
			color: #666;
		}
	}
}

/* 会社紹介 */
.prifile{
	width: 530px;
	position: relative;
	.prifile__contents{
		min-height: 465px;
		border: 10px solid;
	}
	.prifile__title{
		@extend .wf-notosansjapanese;
		padding: 10px 0;
		font-size: 111%;	
	}
	&.prifile--blue{
		float: left;
		.prifile__title{
			color: #2196F3;
		}
		.prifile__contents{
			background: #E6FFFF;
			border-color: #6FCFEB;
			padding: 20px;
			line-height: 2;
			dl{
				background: #fff;
				padding: 20px;
				dt{
					font-weight: bold;
				}
			}
		}
		.prifile__pic{
			position: absolute;
			top: -40px;
			right: 0;
		}
	}
	&.prifile--yellow{
		float: right;
		.prifile__title{
			color: #F05A24;
		}
		.prifile__contents{
			background: #FFFDF0;
			border-color: #FDF18A;
			padding: 20px;
			line-height: 2;
			dl{
				background: #fff;
				padding: 20px;
				dt{
					font-weight: bold;
				}
			}
		}
		.prifile__pic{
			position: absolute;
			top: -40px;
			right: 0;
		}
	}
}
.company-coutline{
	@extend .table-ptn--02;
	th{
		padding: 8px 10px;
		text-align: center;
	}
	td{
		padding: 8px 10px;
	}
}

/* 流れ */
.flow{
	list-style: none;
	li{
		@extend .clearfix;
		background: url(../img/service/flow/bgi--flow.png) center bottom no-repeat;
		padding-bottom: 60px;
		margin-bottom: 40px;
	}
	dl{
		width: 450px;
		padding: 25px 0 0 100px;
		box-sizing: border-box;
		dt{
			margin-bottom: 30px;
			font-size: 111%;
			font-weight: bold;
		}
	}
	.flow__item-01{
		dl{
			background: url(../img/service/flow/ico--01.png) left top no-repeat;
			width: 100%;
		}
		img{
			vertical-align: top;
		}
		a{
			display: inline-block;
			margin-top: 10px;
		}
	}
	.flow__item-02{
		dl{
			background: url(../img/service/flow/ico--02.png) left top no-repeat;
		}
	}
	.flow__item-03{
		dl{
			background: url(../img/service/flow/ico--03.png) left top no-repeat;
		}
	}
	.flow__item-04{
		dl{
			background: url(../img/service/flow/ico--04.png) left top no-repeat;
		}
	}
	.flow__item-05{
		dl{
			background: url(../img/service/flow/ico--05.png) left top no-repeat;
		}
	}
	.flow__item-06{
		dl{
			background: url(../img/service/flow/ico--06.png) left top no-repeat;
		}
	}
	.flow__item-07{
		dl{
			background: url(../img/service/flow/ico--07.png) left top no-repeat;
		}
	}
	.flow__item-08{
		dl{
			background: url(../img/service/flow/ico--08.png) left top no-repeat;
		}
	}
}

/* サービス */
.service-speed{
	@extend .box-sub--02;
	width: 440px;
	float: right;
	h3{
		margin-top: -40px;
		margin-bottom: 20px;
		text-align: center;
	}
}
.service-category-list{
	@extend .clearfix;
	list-style: none;
	li{
		width: 322px;
		float: left;
		margin-right: 67px;
		&:last-child{
			margin-right: 0;
		}
	}
}
.all-service-list{
	@extend .clearfix;
	list-style: none;
	width: 1010px;
	margin: 0 auto 40px;
	li{
		background: url(../img/common/mark/ico--mark-01.png) 0px 16px no-repeat;
		width: 320px;
		margin-right: 25px;
		padding: 10px 0 10px 20px;
		box-sizing: border-box;
		float: left;
		border-bottom: 1px solid  #C8C8C8;
		&:nth-child(3n){
			margin-right: 0;
		}
		&:nth-child(3n+1){
			clear: both;
		}
	}
}
.service-article-list{
	list-style: none;
	margin-bottom: 60px;
	li{
		width: 255px;
		float: left;
		margin-right: 20px;
		margin-bottom: 40px;
		&:nth-child(3n){
			margin-right: 0;
		}
		&:nth-child(3n+1){
			clear: both;
		}
		strong{
			font-size: 111%;
		}
	}
}
.service-detail-thumb{
	float: left;
	margin-right: 20px;
}
.service-tag{
	list-style: none;
	li{
		background: url(../img/service/detail/ico--tag.png) 5px center no-repeat;
		padding: 5px 10px 5px 25px;
		border: 1px solid  #EC1E79;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;
		font-weight: bold;
		a{
			text-decoration: none;
			color: #EC1E79;
		}
		
	}
}
.service-data{
	margin-bottom: 60px;
	th{
		width: 25%;
		text-align: center;
	}
}

/* トップページ */
.top-main{
	background: #F1EEEB;
	height: 540px;
	padding-bottom: 30px;
	img{
		margin: 0 15px;
	}
	.slick-prev{
		background: url(../img/index/main/btn--prev.png) left top no-repeat;
		left: 50%;
		margin-left: -570px;
		width: 38px;
		height: 38px;
		text-indent: -9999px;
		overflow: hidden;
		z-index: 1000;
	}
	.slick-next{
		background: url(../img/index/main/btn--next.png) left top no-repeat;
		left: 50%;
		margin-left: 530px;
		width: 38px;
		height: 38px;
		text-indent: -9999px;
		overflow: hidden;
	}
	.slick-dots li button:before{
		content: '●';
		font-size: 14px;
	}
	.slick-dots li.slick-active button:before{
	    opacity: .75;
	    color: $color--site-main;
	}
}

.top-about{
	background: url(../img/index/about/bgi--section.jpg) center top no-repeat;
	padding-top: 70px;
	text-align: center;
	.top-about__title{
		margin-bottom: 45px;
	}
}

.top-service{
	background: url(../img/index/service/bgi--section.jpg) right 80px no-repeat;
	padding-left: 25px;
	.top-service__title{
		margin-bottom: 20px;
	}
	.top-service__copy{
		background: #fff;
		width: 616px;
		padding: 10px 20px 10px 10px;
		margin-left: 130px;
		margin-bottom: 40px;
	}
	.top-service__list{
		width: 610px;
		list-style: none;
		li{
			width: 33%;
			float: left;
			text-align: center;
			margin-bottom: 40px;
			img{
				display: block;
				margin: 0 auto 5px;
			}
		}
	}
	.top-service__more-btn{
		width: 610px;
		margin-bottom: 65px;
		text-align: center;
	}
	.top-service__bnr{
		width: 610px;
		text-align: center;
		a{
			display: inline-block;
			margin: 0 10px;
		}
	}
}

.top-facility{
	background: url(../img/index/facility/bgi--section.png) center top no-repeat;
	padding: 120px 0 0;
	.top-facility__title{
		margin-bottom: 60px;
	}
}
.top-facility-main{
	background: url(../img/index/facility/pic--main.jpg) center top no-repeat;
	background-size: cover;
	width: 120%;
	margin-left: -10%;
	height: 510px;
	transform-origin: left center;
	transform: rotate(-3deg);
	img{
		transform-origin: left center;
		transform: rotate(3deg);
		position: absolute;
		left: 50%;
		top: 100px;
		margin-left: -460px;
	}
}

.top-tradition{
	background: url(../img/index/tradition/bgi--section.jpg) center top no-repeat;
	padding-top: 700px;
	min-height: 1404px;
	box-sizing: border-box;
	.top-tradition__title{
		margin-bottom: 40px;
	}
	.top-tradition__bnr{
		text-align: center;
		a{
			display: inline-block;
			margin: 0 85px;
		}
	}
}

.top-staff{
	background: url(../img/index/staff/bgi--section.png) center top no-repeat , url(../img/index/staff/bgi--section-dot.jpg)   ;
	padding: 60px 0 70px;
	.top-staff__content{
		padding-left: 530px;
	}
	.top-staff__title{
		margin-bottom: 40px;
	}
}

.top-contact{
	background: url(../img/index/contact/bgi--section-01.jpg) center top no-repeat , url(../img/index/contact/bgi--section-02.jpg) center bottom no-repeat, url(../img/index/contact/bgi--section-03.jpg) center top repeat-y;
	box-sizing: border-box;
	padding: 60px 35px 35px;
	width: 948px;
	margin: 0 auto 60px;
	.top-contact__title{
		margin-bottom: 50px;
	}
	.top-contact__form{
		background: #fff;
		border: 4px solid  #EC1C24;
		padding: 10px;
	}
}

.top-blog{
	background: url(../img/index/blog/bgi--section.jpg) center center no-repeat;
	background-size:  cover;
	padding: 70px 0 45px;
	text-align: left;
	.top-blog__title{
		margin-bottom: 60px;
		text-align: center;
	}
	.top-blog__list{
		width: 1020px;
		margin: 0 auto 60px;
		list-style: none;
		li{
			width: 315px;
			float: left;
			margin-right: 37px;
			position: relative;
			z-index: 10;
			&:before , &:after{
				z-index: -1;
				position: absolute;
				content: "";
				bottom: 15px;
				left: 10px;
				width: 50%;
				top: 80%;
				max-width:300px;
				background: #777;
				box-shadow: 0 20px 10px rgba(0,0,0,0.3);;
				transform: rotate(-3deg);				
			}
			&:after{
				transform: rotate(3deg);
				right: 10px;
				left: auto;
			}
			&:last-child{
				margin-right: 0;
			}
			span{
				display: block;
			}
			a{
				text-decoration: none;
			}
		}
	}
	.top-blog__container{
		background: #fff;
		padding: 15px;
	}
	.top-blog__date{
		margin-bottom: 5px;
		font-size: 94%;
		font-family: Meiryo;
	}
	.top-blog__article-title{
		height: 64px;
		margin-bottom: 20px;
		line-height: 1.3;
		font-size: 122%;
		font-weight: bold;
		text-decoration: underline;
	}
	.top-blog__more{
		border: 1px solid  #B2B2B2;
		padding: 10px;
		border-radius: 3px;
		text-align: center;
		font-size: 78%;
		color: #808080;
	}
	a:hover{
		.top-blog__more{
			background: $color--site-main;
			border: 1px solid  $color--site-main;
			color: #fff;
		}
	}
}

