@charset "UTF-8";
/*
	ヘッダーの指定
*/

/* ヘッダー外枠 */
.header {
	background: url(../img/common/header/bgi--header.png) repeat-x;
	padding-top: 22px;
	height: 120px;
	box-sizing: border-box;
	position: relative;
	box-shadow: 0px 5px 3px -3px rgba(0,0,0,0.3);
	text-align: left;
	z-index: 10000;
}
/* ヘッダー上段 */

/* ヘッダーロゴ */
.header-sitename {
	display:block;
	width: 222px;
	position: absolute;
	top: 3px;
	left: 50%;
	margin-left: -111px;
}
/* ヘッダーお問い合わせ */
.header-contact{
	width: 600px;
	float: right;
}
.btn--header-contact{
	width: 300px;
	text-align: center;
}
